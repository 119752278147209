import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Container from "../components/Container"
import ContactForm from '../components/ContactForm';
import { PageTitle } from "../components/Titles";

const ConnectPage = () => {
  
  return (
    <Layout>
      <Seo title="Connect" />
        <Container className={`py-16 md:py-20`} size={`lg`} style={{minHeight: '80vh'}}>
            <div className={`text-center max-w-2xl mx-auto`}>
            <PageTitle size={`text-4xl md:text-6xl font-medium`}>Connect</PageTitle>
            <ContactForm className={`mt-16 text-left`} />
            </div>
            
        </Container>
      
 
    </Layout>
  )
}

export default ConnectPage
